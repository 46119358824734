import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './MentionsVisualizer.module.scss';
import ClipCluster from './ClipCluster/ClipCluster';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import APIClips from 'src/API/APIClips';
import { MentionsVisualizerTypes } from './MentionsVisualizer.types';
import ClipFilters from './ClipFilters/ClipFilters';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import { useTranslation } from 'react-i18next';
import { getChannels } from 'src/redux/actions/actions.channels';
import { chunkArray, dateToString } from '../common/helpers';
import { isMentionFindr } from 'src/Widgets/common/helpers';
import Popup from '../common/basicElements/Popup/Popup';
import SimpleSchedule from '../SearchAgents/SimpleSchedule/SimpleSchedule';
import config from 'src/config/config';
import useFetchClips from './hooks/useFetchClips';
import LoadingSpinner from '../common/basicElements/LoadingSpinner/LoadingSpinner';
import TableContainer from '../ChannelGroups/containers/TableContainer/TableContainer';
import { Channel } from 'src/@types/shared.types';
import { setSelectedChannelIds, unselectChannels } from 'src/redux/actions/actions.channelGroupsWidget'; 
import APIProfileGroups from 'src/API/APIProfileGroups';
import { MessageHandler } from 'src/API/MessageHandler/MessageHandler';
import ForbiddenChannelsHandler from '../Search/ForbiddenChannelsHandler';
import { addFailedAction } from 'src/redux/actions/actions.failedActions';

export const filterByProfile = (clip: any, profile: any) => 
    !profile || clip.profileID === profile.value.id;

export const filterByLanguage = (clip: any, language: any) => 
    !language || clip.language.slice(0, 2) === language.value;

export const filterByChannelGroup = (clip: any, selectedChannelGroup: any) => 
    !selectedChannelGroup || clip.selectedChannelGroup === selectedChannelGroup;

export const filterByEditor = (clip: any, editor: any) => 
    !editor || clip.editor === editor.value;

export const filterByDuplicate = (clip: any, isDuplicateShown: boolean) => 
    !isDuplicateShown || clip.dupType === 'channel' || clip.dupType === '*' || clip.dupType === 'clip' || clip.dupType === 'network';

export const filterByMediaType = (clip: any, mediaType: any) => 
    !mediaType || clip.mediatype === mediaType.value;

export const filterByStatus = (clip: any, status: any) => {
    if(status && status.value === 'todo'){
        return clip.state === null
    }
    return !status || clip.state === status.value;
}

export const filterByQueryLanguage = (clip: any, queryLanguage: any) =>
    !queryLanguage || (clip.basicNotifications && clip.basicNotifications.some((basicNotification: MentionsVisualizerTypes.Clip) => {
        return basicNotification.language === queryLanguage.value;
    }));

export const filterByProfileQuery = (clip: MentionsVisualizerTypes.Clip, profileQuery: any) =>
    !profileQuery || (clip.basicNotifications && clip.basicNotifications.some((basicNotification: MentionsVisualizerTypes.Clip) => {
        return basicNotification.qid === profileQuery.id
    }));

    export const filterByProfileGroup = (clip: any, profileGroup: any) => {
        return (
            !profileGroup ||
            (profileGroup.sharedBy !== null
                ? clip.profileGroupTitle === profileGroup.value.title.replace('[SH]', '')
                : clip.profileGroupTitle === profileGroup.value.title)
        );
    }

export const getCounts = async (profileIds: any[] | string, startDate: string, endDate: string) => {
    const chunkSize = 20;
    const convertedProfileIds = typeof profileIds === 'string' ? profileIds.split(',') : profileIds;

    const idChunks = chunkArray(convertedProfileIds, chunkSize);

    const results = await Promise.all(
        idChunks.map(async (chunk) => {
            return await APIClips.getCountsByProfile(chunk, startDate, endDate);
        })
    );

    return results.reduce((acc, result) => {
        if (result.rc === 0) {
            acc.push(result);
        }
        return acc;
    }, []);
};

const MentionsVisualizer = ({
    isRecent,
    isEdited,
    isSearch,
    isEditorial,
    tryQuery,
    setTryQueryProfileId,
    externalProfileId,
    externalDateRange,
    onProfileSavedSuccess
}: MentionsVisualizerTypes.MentionsVisualizerProps) => {

    const { t } = useTranslation();

    const initialDateRange = tryQuery && isSearch ? {
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
        customRange: t('Last 30 days')
    } : isEditorial ? {
        startDate: new Date(new Date().setDate(new Date().getDate() - 2)),
        endDate: new Date(),
        customRange: t('Last 2 days')
    } : {
        startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        endDate: new Date(),
        customRange: 'Custom Range'
    }

    const initialFilter =  {
        profileGroup: null,
        profile: '',
        language: '',
        channelGroupTitle: '',
        selectedChannelGroup: undefined,
        editor: '',
        search: '',
        dateRange: initialDateRange,
        mediaType: '',
        isDuplicateShown: false,
        status: '',
        queryLanguage: '',
        profileQuery: ''
    }     

    const [hasSearched, setHasSearched] = useState(false);
    const [modalType, setModalType] = useState<string | undefined>(undefined);
    const [isSelectChannelsShown, setIsSelectChannelsShown] = useState(false);
    const [filteredData, setFilteredData] = useState<MentionsVisualizerTypes.Clip[]>([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [currentProfileGroup, setCurrentProfileGroup] = useState<MentionsVisualizerTypes.ProfileGroup | any>();
    const [selectedDateRange, setSelectedDateRange] = useState<{startDate: Date;endDate: Date;customRange?: string;}>(initialDateRange);
    const [filter, setFilter] = useState<MentionsVisualizerTypes.Filter>(initialFilter);
    const [isToggleChannels, setIsToggleChannels] = useState((filter.selectedChannelGroup || tryQuery?.channelGroup) ? true : false);
    const [isToggleProfileGroups, setIsToggleProfileGroups] = useState(true);
    const [isDateRangeSet, setIsDateRangeSet] = useState(false);
    const [clipStates, setClipStates] = useState<any>({});
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [profileGroups, setProfileGroups] = useState<MentionsVisualizerTypes.ProfileGroupNative[]>([]);
    const [isQueryVizOverlayActive, setIsQueryVizOverlayActive] = useState(false);
    const [searchGroupExists, setSearchGroupExists] = useState(false);
    const [searchProfileGroup, setSearchProfileGroup] = useState<any>(null);
    const [isClipsBackfilled, setIsClipsBackfilled] = useState(false);
    const [selectedCardElements, setSelectedCardElements] = useState<any[]>([]);
    const [searchRetryCount, setSearchRetryCount] = useState(0);
    const [isLoadingMsg, setIsLoadingMsg] = useState('');
    const [queryIssue, setQueryIssue] = useState<string>();

    const isMobileScreen = useIsScreenSize(840);
    const isEmbed = window.location.href.includes('embed');
    const dispatch = useAppDispatch();
    const profileGroupsRedux = useAppSelector((state) => state.profileGroups.data);
    const channelGroupsRedux = useAppSelector((state) => state.channelGroupReducers);

    const clustersContainerRef = useRef<HTMLDivElement>(null);

    const getProfileGroupsHandler = async () => {
        try {
            const res = await APIProfileGroups.get();
            if(res.rc === 0){
                setProfileGroups(res.data);
            }
        } catch (error) {
            console.error('Error fetching profile groups:', error);
        }
    }
    const messageHandlerRef = useRef<MessageHandler>();

    const onSearchIsSlow = () => {
        setSearchRetryCount((prevCount) => {
            if (prevCount === 0) {
                setIsLoadingMsg(t('search_taking_long_msg'));
                fetchSearchQueryClips(filter.search, filter.dateRange);
                return 1;
            } else {
                setLoading(false);
                setIsLoadingMsg('');
                dispatch(addFailedAction({ resolution: 'TRY_LATER', data: { action: { type: t('Get'), itemName: t('search results') } } }));
                setSearchRetryCount(0);
                return prevCount;
            }
        });
    };

    const { data, loading, fetchClips, fetchEditedClips, fetchSearchQueryClips, filterClipsByBackfill, searchForbiddenChannels, setSearchForbiddenChannels, setLoading} =
        useFetchClips(
            selectedDateRange,
            filter.selectedChannelGroup,
            isToggleChannels,
            isSearch,
            tryQuery,
            profileGroupsRedux,
            setTryQueryProfileId,
            setModalType,
            onSearchIsSlow,
            setIsLoadingMsg,
            onProfileSavedSuccess,
            setQueryIssue
            //currentProfileGroup
        );

    const allProfileIds: any = (
        isSearch && searchProfileGroup ? [searchProfileGroup] : profileGroups
    ).filter((group) => group.profiles.length > 0)
    .map((group) => group.profiles.map((profile: { id: any }) => profile.id))
    .flat();

    const currentProfileIds = currentProfileGroup
        ? currentProfileGroup.profiles.map((profile: { id: any }) => profile.id)
        : allProfileIds;

    const updatedDataWithStates = useMemo(() => data.map((clip) => {
            const matchedState = clipStates?.states?.find(
                (clipState: any) => Number(clipState.id) === clip.notifID
            );
            return { 
                ...clip, 
                state: matchedState ? matchedState.state : null 
            };
    }), [data, clipStates]);  

    const applyFilters = async (filter: any) => {
            const {
                profileGroup,
                profile,
                language,
                selectedChannelGroup,
                editor,
                mediaType,
                isDuplicateShown,
                dateRange,
                status,
                queryLanguage,
                profileQuery
            } = filter;
        
            // Set current profile group based on conditions
            if (isRecent || isEdited || isToggleProfileGroups) {
                setCurrentProfileGroup(profileGroup);
            }
        
            // Update date range if it has changed
            if (
                dateRange.startDate.getTime() !== selectedDateRange.startDate.getTime() ||
                dateRange.endDate.getTime() !== selectedDateRange.endDate.getTime()
            ) {
                setSelectedDateRange(dateRange);
            }

            // Collect filter functions based on input
            const filters = [
                (clip: any) => filterByProfile(clip, profile),
                (clip: any) => filterByLanguage(clip, language),
                (clip: any) => filterByChannelGroup(clip, selectedChannelGroup),
                (clip: any) => filterByEditor(clip, editor),
                (clip: any) => filterByDuplicate(clip, isDuplicateShown),
                (clip: any) => filterByMediaType(clip, mediaType),
            ];
        
            // Include profile group filter conditionally
            if (isRecent || isEdited || isToggleProfileGroups) {
                filters.push((clip: any) => filterByProfileGroup(clip, profileGroup));
            }

            if(isEditorial){
                filters.push((clip: any) => filterByStatus(clip, status));
                filters.push((clip: any) => filterByQueryLanguage(clip, queryLanguage));
                filters.push((clip: any) => filterByProfileQuery(clip, profileQuery));
            }
        
            // Pass filters to handleApplyFilters
            handleApplyFilters(filters);
    };        

    //Apply filters initially if external profileId and dateRange is set
    useEffect(() => {
        if(externalProfileId && externalDateRange){
            const group = profileGroups.find((group) => group.id === externalProfileId);
            applyFilters({
                ...filter,
                dateRange: {
                    startDate: externalDateRange[0],
                    endDate: externalDateRange[1]
                },
                profileGroup: group ? {
                    value: {
                        id: group.id,
                        title: group.title
                    },
                    label: group.title,
                    profiles: group.profiles,
                    sharedBy: null
                } : null
            });
        }
        setCurrentProfileGroup(
            profileGroupsRedux.find((group: MentionsVisualizerTypes.ProfileGroup) => {
                return group.profiles.some((profile: { id: any }) => profile.id === externalProfileId)
            })
        )    
    },[externalProfileId, externalDateRange]);

    const handleApplyFilters = async (filters: ((clip: any) => boolean)[]) => {
        setLoading(true);
        try {
            // If editorial mode is active
            if (isEditorial) {    
                // Filter the updated data, memoization already done outside this function
                const newFilteredData = updatedDataWithStates.filter((clip) => 
                    filters.every((filter) => filter(clip))
                );

                // Set filtered data based on the result
                setFilteredData(newFilteredData.length ? newFilteredData : []);
                setIsFiltered(true);
                setFiltersApplied(true);
            } else {
                // If not in editorial mode, apply filters directly
                if (isSearch) setHasSearched(true);
                
                const filteredData = data.filter((clip) => 
                    filters.every((filter) => filter(clip))
                );
    
                // Set filtered data
                setFilteredData(filteredData);
            }
    
        } catch (error) {
            console.error('Error applying filters:', error);
        }
    };
    

    const fetchCountsStructureGenerator = async () => {
        const isEditedIds = currentProfileIds.map((id: any) => `${id}e`);
        const idsToUse = isEdited ? isEditedIds : currentProfileIds;
        const res = idsToUse.length > 0
            ? await getCounts(idsToUse, dateToString(selectedDateRange?.startDate), dateToString(selectedDateRange?.endDate))
            : [];
    
        // Check if any of the res objects has rc 0
        const hasRcZero = res.some((res: any) => res.rc === 0);
        if (hasRcZero) {
            // Extract data from res objects with rc 0
            const profileHasCount = res
                .filter((result: any) => result.rc === 0)
                .flatMap((result: any) => result.data)
                .filter((profile: { _count: number }) => profile._count > 0) || [];
    
            const uniqueProfileHasCount = [
                ...new Set(
                    profileHasCount.map((profile: { profileID: any }) => profile.profileID)
                )
            ] as any[];
    
            // Check uniqueProfile Ids which profile group they belong to, if the ids are the same show only unique
            const profileGroupWithMostCount = (() => {
                const profileGroups = uniqueProfileHasCount.map((profileId: number) => findProfileGroupByProfile(profileId));
    
                const groupCountMap = profileGroups.reduce((acc: any, group: any) => {
                    if (group) {
                        acc[group.id] = (acc[group.id] || 0) + 1;
                    }
                    return acc;
                }, {});
    
                const mostCountGroup = Object.keys(groupCountMap).reduce((a, b) => groupCountMap[a] > groupCountMap[b] ? a : b, '');
                return profileGroups.find((group: any) => group?.id === Number(mostCountGroup));
            })();
    
            // Fetch data for the first profile group with count
            const firstGroupWithCountProfiles = profileGroupWithMostCount?.profiles.map(
                (profile: { id: any }) => profile.id
            ) || [];

            if (isEdited && !isSearch) {
                fetchEditedClips(firstGroupWithCountProfiles);
            } else if (((isRecent && !isSearch) || (isEditorial && isToggleProfileGroups)) && !firstGroupWithCountProfiles.includes(undefined)) {
                fetchClips(isEditorial && !isToggleProfileGroups ? uniqueProfileHasCount : firstGroupWithCountProfiles, selectedDateRange);
            }
    
            const profileGroupToSet = profileGroupWithMostCount;
            (isToggleProfileGroups || !isEditorial) && profileGroupToSet && setFilter((prevFilter) => ({
                ...prevFilter,
                profileGroup: {
                    value: {
                        id: profileGroupToSet?.id,
                        title: profileGroupToSet.sharedBy ? '[SH]' + profileGroupToSet?.title : profileGroupToSet?.title
                    },
                    label: profileGroupToSet.sharedBy ? '[SH]' + profileGroupToSet?.title : profileGroupToSet?.title,
                    profiles: profileGroupToSet?.profiles,
                    sharedBy: profileGroupToSet?.sharedBy || null
                }
            }));
        }
    };

    const findProfileGroupByProfile = (profileId: number | string) => {
        const profileGroup = profileGroups.find(
            (group: { profiles?: { id: number }[] }) =>
                group.profiles?.some((profile: { id: number }) => profile.id === profileId)
        );
        return isSearch ? searchProfileGroup : profileGroup;
    };

    useEffect(() => {
        if(isSearch){
            const exists = profileGroupsRedux.some(
                (group: any) => group.title === config.searchQueryGroupTitle
            );
            setSearchGroupExists(exists);
        
            const profileGroup = exists
                ? profileGroupsRedux.find((group: any) => group.title === config.searchQueryGroupTitle)
                : null;
            setSearchProfileGroup(profileGroup);
        }
    }, [data, profileGroupsRedux]);

    useEffect(() => {
        if (isEditorial) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                status: null,
                queryLanguage: null,
                profileQuery: null
            }));
        }
    }, [filter.dateRange, filter.profileGroup])

    useEffect(() => {
        if (!isToggleProfileGroups) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                profileGroup: null
            }));
        }
    }, [filtersApplied]);

    useEffect(() => {
        if (!isToggleProfileGroups && isEditorial) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                profileGroup: null
            }));
        }
    }, [isToggleProfileGroups]);

    useEffect(() => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            dateRange: {
                startDate: selectedDateRange.startDate,
                endDate: selectedDateRange.endDate,
                customRange: selectedDateRange.customRange
            },
        }));
    }, [selectedDateRange]);

    useEffect(()=> {
        if(isSearch || isEdited || isEditorial || isRecent){
            dispatch(setSelectedChannelIds([]));
        }
    }, [isSearch, isEdited, isEditorial, isRecent]);
    
    useEffect(() => {
        if (isEditorial) {
            messageHandlerRef.current = new MessageHandler();
        }
        dispatch(unselectChannels());
        setFilteredData(data);
        getProfileGroupsHandler();
        dispatch(getChannels());
        tryQuery?.channelGroup && 
        setFilter((prevFilter) => {
            return {
               ...prevFilter,
                selectedChannelGroup: {
                    value: tryQuery?.channelGroup,
                    label: tryQuery?.channelGroup.title
                }
            };
        })
    }, []);

    useEffect(() => {
        if (
            !isEmbed &&
            (isEditorial || (!isSearch && !isEditorial)) &&
            (selectedDateRange || currentProfileGroup) &&
            !filter.status &&
            !filter.queryLanguage &&
            !filter.profileQuery
        ) {
            fetchCountsStructureGenerator();
        }
    }, [profileGroups, selectedDateRange, currentProfileGroup, isEditorial, isSearch]);

    useEffect(() => {
        if (
            isEmbed &&
            profileGroups &&
            !filter.status &&
            !filter.queryLanguage &&
            !filter.profileQuery
        ) {
            fetchCountsStructureGenerator();
        }
    }, [profileGroups, isEmbed, currentProfileGroup, selectedDateRange]);

    useEffect(() => {
        if(isEditorial){
            setFiltersApplied(false);
        }
    }, [isToggleProfileGroups])

    useEffect(() => {
        if(!searchGroupExists && isSearch){
            getProfileGroupsHandler();
        };
    }, [searchGroupExists])

    const sortClips = (clips: MentionsVisualizerTypes.Clip[]) => {
        return clips.sort((a, b) => {
            const dateA = new Date(a.hitTime);
            const dateB = new Date(b.hitTime);
            return dateB.getTime() - dateA.getTime();
        });
    }

    const profileGroupHasNoProfiles = (profileGroup: MentionsVisualizerTypes.ProfileGroup) => {
        return profileGroup?.profiles.length === 0;
    }

    const isNoData = () => {
        return (
            data.length === 0 ||
            profileGroupHasNoProfiles(currentProfileGroup) ||
            (isEditorial && isFiltered && filteredData.length === 0) ||
            (isEditorial && !isFiltered && data.length === 0)
        );
    }

    const getNoDataMessage = () => {
        if (isEdited) return t('No edited clips found');
        if(isEditorial && isRecent && ((filter.status || filter.queryLanguage || filter.profileQuery))) return t('No result for applied filters found');
        if(isEditorial && isRecent) return t('No recent clips found');
        if (isRecent && !isEditorial) return t('No recent clips found');
        if (!isDateRangeSet) return t('Please select a date range to start searching');
        if(isSearch && isClipsBackfilled) return t('All results were backfilled, please execute search again')
        if (isSearch)
            return (data.length === 0 && !hasSearched || !isClipsBackfilled)
                ? t('Type your query to start a search')
                : t('No search results found');
        return t('Please write a query for search results');
    }

    const groupClipsByProfileGroup = (
        clips: MentionsVisualizerTypes.Clip[],
        profileGroups: any[],
        isEditorial?: boolean
    ) : MentionsVisualizerTypes.ProfileGroupContent[]  => {
        if (isEditorial && !isToggleProfileGroups) {
            // Create a dummy profile group called "All Group View"
            const allGroupView: MentionsVisualizerTypes.ProfileGroupContent = {
                id: 'all-group-view',
                title: 'All Groups View',
                clips: sortClips(clips),
                profiles: [],
                sharedBy: null
            };
    
            // Return the dummy profile group
            return [allGroupView];
        }else if(tryQuery){
            const tryQueryView: MentionsVisualizerTypes.ProfileGroupContent = {
                id: 'try-query-view',
                title: t('profile_try_query'),
                clips: sortClips(clips),
                profiles: [],
                sharedBy: null
            };
            return [tryQueryView];
        } else {
            // Original grouping by profile group logic
            const profileGroupContent = profileGroups.map(
                (group: { id: number; title: any; profiles: any, sharedBy: number | null }) => {
                    const clipsForGroup = clips.filter(
                        (clip: MentionsVisualizerTypes.Clip) => {
                            const profileId = clip.profileID;
                            const profileGroup = profileId
                                ? findProfileGroupByProfile(profileId)
                                : null;
                            return profileGroup && profileGroup.id === group.id;
                        }
                    );
                    const clipsToPass = clipsForGroup.map((clip) => {
                        const profileId = clip.profileID;
                        return profileId
                            ? {
                                  ...clip,
                                  profileId
                              }
                            : clip;
                    });
    
                    const sortedClips = sortClips(clipsToPass);
    
                    return {
                        id: group.id,
                        title: group.title,
                        clips: sortedClips,
                        profiles: group.profiles,
                        sharedBy: group.sharedBy
                    };
                }
            );
            return profileGroupContent;
        }
    };

    const profileGroupContent = useMemo(() => {
        return groupClipsByProfileGroup(
            (!isFiltered || !isEditorial) ? data : filteredData,
            (isSearch ? profileGroupsRedux : profileGroups),
            isEditorial
        );
    }, [isFiltered, isEditorial, data, filteredData, profileGroups, profileGroupsRedux]);

    // Sync filteredData with data when isEditorial is true
    useEffect(() => {
        if (isEditorial) {
            setFilteredData(data);
        }
    }, [data, isEditorial]);

    const isMentionFindrApp = isMentionFindr();

    let modal;
    if (modalType) {
        switch (modalType) {
            case 'changeSchedule':
                modal = (
                    // @ts-ignore
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={() => {
                            setModalType(undefined);
                        }}
                        onCancel={() => {
                            setModalType(undefined);
                        }}
                    >
                        <SimpleSchedule />
                    </Popup>
                );
                break;
            case 'saveSearchAgent':
                modal = (
                    // @ts-ignore
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={() => {
                            setModalType(undefined);
                        }}
                        onCancel={() => {
                            setModalType(undefined);
                        }}
                    >
                        {t('want_to_add_search_agent')}
                    </Popup>
                );
                break;
            case 'deleteSearchAgent':
                modal = (
                    // @ts-ignore
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={() => {
                            setModalType(undefined);
                        }}
                        onCancel={() => {
                            setModalType(undefined);
                        }}
                    >
                        {t('really_delete_search_agent')}
                    </Popup>
                );
                break;
            case 'multipleWidgetsRestriction':
                modal = (
                    // @ts-ignore
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={() => {
                            if(isSearch && filter.search) {
                                fetchSearchQueryClips(filter.search, filter.dateRange);
                            }else{
                                window.location.reload();
                            }
                            setModalType(undefined);
                        }}
                        okTxt={t('Reload')}
                    >
                        {t('multiple_widgets_restriction')}
                    </Popup>
                );
                break;    
            case 'searchForbiddenChannels':
                modal = (<ForbiddenChannelsHandler
                    channelIdsInGroup={channelGroupsRedux.find((channelGroup:any) => channelGroup.id === filter?.selectedChannelGroup?.value?.id).channels.map((channel:any)=>channel.id)}
                    channelGroupId={filter?.selectedChannelGroup?.value?.id}
                    forbiddenChannels={searchForbiddenChannels}
                    onCloseModal={() => {
                        setModalType(undefined);
                        setSearchForbiddenChannels([]);
                    }}
                    onDeletedChannels={(deletedAllChannelsFromGroup) => { 
                        if (deletedAllChannelsFromGroup) {
                            setFilter((prevFilter) => {
                                return {
                                   ...prevFilter,
                                    selectedChannelGroup: null
                                };
                            })
                        } else {
                            fetchSearchQueryClips(filter.search, filter.dateRange);
                        }
                     }}
                />)
                break;
        }
    }

    const getMentionsVisualizerStyle = (
        isMobileScreen: boolean,
        isMentionFindrApp: boolean,
        isSearch?: boolean
    ) => {
        if (isSearch && !isMobileScreen) {
            return {
                height: '100%',
                overflow: 'hidden'
            };
        } else if (isMentionFindrApp) {
            return { lineHeight: '1' };
        } else {
            return {};
        }
    };

    const generalFiltersComponent = <ClipFilters
    data={data}
    isToggleChannels={isToggleChannels}
    isToggleProfileGroups={isToggleProfileGroups}
    setIsToggleChannels={setIsToggleChannels}
    setIsToggleProfileGroups={setIsToggleProfileGroups}
    isEdited={isEdited}
    isRecent={isRecent}
    isSearch={isSearch}
    isEditorial={isEditorial}
    isSelectChannelsShown={isSelectChannelsShown}
    setIsSelectChannelsShown={setIsSelectChannelsShown}
    setModalType={setModalType}
    modalType={modalType}
    fetchSearchClips={fetchSearchQueryClips}
    tryQuery={tryQuery}
    isDateRangeSet={isDateRangeSet}
    setIsDateRangeSet={setIsDateRangeSet}
    setFilter={setFilter}
    filter={filter}
    applyFilters={applyFilters}
    setIsQueryVizOverlayActive={setIsQueryVizOverlayActive}
    isQueryVizOverlayActive={isQueryVizOverlayActive}
    queryIssue={queryIssue}
    setQueryIssue={setQueryIssue}
/>;

return (
    <div
        className={styles.mentionsVisualizerContainer}
        ref={clustersContainerRef}
        data-testid="cluster-container"
        style={getMentionsVisualizerStyle(
            isMobileScreen,
            isMentionFindrApp,
            isSearch
        )}
    >
        {modal}
        {generalFiltersComponent}

        {isSelectChannelsShown ? (
            // @ts-ignore
            <TableContainer
                onSelectionChange={(channelIds: Channel[]) => {
                    dispatch(setSelectedChannelIds(channelIds));
                }}
                isChannelGroupsWidget={false}
            />
        ) : loading ? (
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <div className={styles.loadingSpinnerContainer}>
                        <LoadingSpinner key={0} size="4rem" />
                    </div>
                    <span style={{ marginTop: '10px' }}>{isLoadingMsg}</span>
                </div>
        ) : isNoData() ? (
            <div className={styles.noDataMessage}>{getNoDataMessage()}</div>
        ) : (
            profileGroupContent.map(
                (
                    profileGroupContent: MentionsVisualizerTypes.ProfileGroupContent,
                    index: number
                ) => (
                    (profileGroupContent.clips.length > 0 && isSearch) ||
                    profileGroupContent.clips.length > 0 ? (
                        <ClipCluster
                            profileGroupContent={profileGroupContent}
                            currentProfileGroup={currentProfileGroup}
                            key={index}
                            data={data}
                            filteredData={filteredData}
                            filter={filter}
                            selectedDateRange={selectedDateRange}
                            setClipStates={setClipStates}
                            clipStates={clipStates}
                            isSearch={isSearch}
                            isEdited={isEdited}
                            isEditorial={isEditorial}
                            isMentionFindrApp={isMentionFindrApp}
                            messageHandler={messageHandlerRef.current}
                            tryQuery={tryQuery}
                            setIsClipsBackfilled={setIsClipsBackfilled}
                            isClipsBackfilled={isClipsBackfilled}
                            selectedCardElements={selectedCardElements}
                            setSelectedCardElements={setSelectedCardElements}
                            filterClipsByBackfill={filterClipsByBackfill}
                            externalProfileId={externalProfileId}
                            setModalType={setModalType}
                        />
                    ) : null
                )
            )
        )}
    </div>
);
};

export default MentionsVisualizer;
