import React, { useEffect, useRef, useState } from 'react';
import styles from './ClipFilters.module.scss';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getGroups } from 'src/redux/actions/actions.channelGroups';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import ClipFiltersTopBar from './ClipFiltersTopBar/ClipFiltersTopBar';
import useClipCounts from '../hooks/useClipCounts';
import ClipFiltersSideBar from './ClipFiltersSideBar/ClipFiltersSideBar';
import config from 'src/config/config';
import { dateToString } from 'src/Widgets/common/helpers';
import { getProfileGroups } from 'src/redux/actions/actions.profileGroups';

const ClipFilters: React.FC<MentionsVisualizerTypes.ClipFiltersProps> = ({
    applyFilters,
    isToggleChannels,
    isToggleProfileGroups,
    setIsToggleChannels,
    setIsToggleProfileGroups,
    data,
    isEdited,
    isRecent,
    isSearch,
    isEditorial,
    isSelectChannelsShown,
    setIsSelectChannelsShown,
    setFilter,
    filter,
    fetchSearchClips,
    setModalType,
    modalType,
    tryQuery,
    isDateRangeSet,
    setIsDateRangeSet,
    setIsQueryVizOverlayActive,
    isQueryVizOverlayActive,
    queryIssue,
    setQueryIssue
}) => {

    const [activeSearchHistoryItemId, setActiveSearchHistoryItemId] = useState<
        number | null
    >(null);
    const [showQueryVis, setShowQueryVis] = useState<boolean>(false);

    // get profile groups from redux store
    const profileGroups = useAppSelector((state) => state.profileGroups.data);
    const dispatch = useAppDispatch();

    const searchGroup = profileGroups.find(
        (group: { title: string }) => group.title === config.searchQueryGroupTitle
    );

    const { clipCounts, searchClipCounts, fetchCountsHandler } =
        useClipCounts({
            profileGroups,
            searchGroup,
            currentProfileGroup: filter.profileGroup,
            isSearch,
            isEdited
        });

    // get profile and channel groups
    useEffect(() => {
        dispatch(getGroups());
        if(profileGroups.length === 0){
            dispatch(getProfileGroups());
        }
    }, [])

    return (
        <div
            className={styles.clipFiltersContainer}
            style={isRecent || isEdited ? { alignItems: 'flex-start' } : {}}
        >
           

            <ClipFiltersSideBar
                filter={filter}
                applyFilters={applyFilters}
                data={data}
                searchGroup={searchGroup}
                setFilter={setFilter}
                fetchSearchClips={fetchSearchClips}
                clipCounts={clipCounts}
                searchClipCounts={searchClipCounts}
                isSelectChannelsShown={isSelectChannelsShown}
                setIsSelectChannelsShown={setIsSelectChannelsShown}
                fetchCountsHandler={fetchCountsHandler}
                isSearch={isSearch}
                isEdited={isEdited}
                isRecent={isRecent}
                isEditorial={isEditorial}
                activeSearchHistoryItemId={activeSearchHistoryItemId}
                setActiveSearchHistoryItemId={setActiveSearchHistoryItemId}
                tryQuery={tryQuery}
                setIsDateRangeSet={setIsDateRangeSet}
                isToggleChannels={isToggleChannels}
                isToggleProfileGroups={isToggleProfileGroups}
                setIsToggleChannels={setIsToggleChannels}
                setIsToggleProfileGroups={setIsToggleProfileGroups}
                setIsQueryVizOverlayActive={setIsQueryVizOverlayActive}
                queryIssue={queryIssue}
                setShowQueryVis={setShowQueryVis}
            />
             {isSearch &&
                <ClipFiltersTopBar
                    isSearch={isSearch}
                    isEdited={isEdited}
                    isRecent={isRecent}
                    fetchSearchClips={fetchSearchClips}
                    setFilter={setFilter}
                    //setActiveSearchHistoryItemId={setActiveSearchHistoryItemId}
                    isSelectChannelsShown={isSelectChannelsShown}
                    setIsSelectChannelsShown={setIsSelectChannelsShown}
                    activeSearchHistoryItemId={activeSearchHistoryItemId}
                    setModalType={setModalType}
                    modalType={modalType}
                    filter={filter}
                    applyFilters={applyFilters}
                    tryQuery={tryQuery}
                    isDateRangeSet={isDateRangeSet}
                    setQueryIssue={setQueryIssue}
                    queryIssue={queryIssue}
                    setIsQueryVizOverlayActive={setIsQueryVizOverlayActive}
                    isQueryVizOverlayActive={isQueryVizOverlayActive}
                    setShowQueryVis={setShowQueryVis}
                    showQueryVis={showQueryVis}
                />
            }
        </div>
    );
};

export default ClipFilters;
