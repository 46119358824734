import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import APIClips from 'src/API/APIClips';
import { dateToString, stringToHash } from 'src/Widgets/common/helpers';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import APIProfileGroups from 'src/API/APIProfileGroups';
import config from 'src/config/config';
import {
    addProfile,
    deleteProfile,
    getProfileGroups,
    updateProfile
} from 'src/redux/actions/actions.profileGroups';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import APIProfiles from 'src/API/APIProfiles';
import { ChannelGroup } from 'src/Widgets/ChannelGroupsNext/types/types';
import {
    addChannels,
    addGroup,
    deleteGroup,
    getGroups,
    removeChannels,
    updateGroup
} from 'src/redux/actions/actions.channelGroups';
import { Channel } from 'src/@types/shared.types';
//@ts-ignore
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { chunkArray } from 'src/Widgets/common/helpers-tsx';

const useFetchClips = (
    selectedDateRange: MentionsVisualizerTypes.DateRange,
    selectedChannelGroup?: MentionsVisualizerTypes.SelectedChannelGroup,
    isToggleChannels?: boolean,
    isSearch?: boolean,
    tryQuery?: any,
    profileGroupsRedux?: MentionsVisualizerTypes.ProfileGroupNative[],
    setTryQueryProfileId?: (profileID: number) => void,
    setModalType?: (modalType: string) => void,
    onSearchIsSlow?: () => void,
    setIsLoadingMsg?: (modalType: string) => void,
    onProfileSavedSuccess?: (saveProfileAPIResponse: any) => void,
    setQueryIssue?: Dispatch<SetStateAction<string | undefined>>,
    //currentProfileGroup?: MentionsVisualizerTypes.ProfileGroupNative | null | undefined
) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MentionsVisualizerTypes.Clip[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [searchForbiddenChannels, setSearchForbiddenChannels] = useState([]);

    const { t } = useTranslation();

    const withLoading = async (fn: Function, ...args: any[]) => {
        setLoading(true);
        try {
            await fn(...args);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    const selectedChannels = useAppSelector(
        (state) => state.channelGroupsWidgetReducer.selectedChannels
    );

    const searchGroupExists = profileGroupsRedux?.some(
        (group: any) => group.title === config.searchQueryGroupTitle
    );

    const searchProfileGroup = searchGroupExists
        ? profileGroupsRedux?.find(
              (group: any) => group.title === config.searchQueryGroupTitle
          )
        : null;

    const dispatch = useAppDispatch();
    const profileGroups = useAppSelector((state) => state.profileGroups.data) || [];

    const handleChannelsInChunks = async (
        action: 'add' | 'remove',
        groupId: string,
        channels: any[]
    ) => {
        if (channels.length > 1000) {
            setLoading(true);
        }
        const chunks = chunkArray(channels, 1000);
        for (const chunk of chunks) {
            if (action === 'add') {
                await dispatch(addChannels(groupId, chunk));
            } else if (action === 'remove') {
                await dispatch(removeChannels(groupId, chunk));
            }
        }
    };

    const createOrUpdateChannelGroupForProfile = async (
        channels: Channel[],
        searchQuery?: string
    ) => {
        const channelGroups = await dispatch(getGroups());
        try {
            let tryQueryTempChannelGroup = channelGroups.find(
                (group: ChannelGroup) =>
                    group.title === `${config.searchChannelGroupTitle + 'tryQuery'}`
            );
            if (selectedChannels.length === 0) return null;
            // Generate a unique group title for the profile
            const uniqueGroupTitle = `${config.searchChannelGroupTitle + stringToHash(searchQuery)}`;

            const searchChannelGroups = channelGroups.filter(
                (group: ChannelGroup) =>
                    group.title.startsWith(config.searchChannelGroupTitle) &&
                    !group.title.endsWith('tryQuery')
            );
            
            //!TODO - add logic for handling update channel group belongs to first one when there is no channel group selected
            // Check if the channel group already exists
            let searchChannelGroup = searchChannelGroups.find(
                (group: ChannelGroup) =>
                    group.title === `${config.searchChannelGroupTitle + searchQuery}` ||
                    group.title === `${config.searchChannelGroupTitle + stringToHash(searchQuery)}`
            );

            if (searchChannelGroup && !tryQuery) {
                // Remove all current channels
                await handleChannelsInChunks('remove', searchChannelGroup?.id, searchChannelGroup.channels);
                // Add the new selected channels
                await handleChannelsInChunks('add', searchChannelGroup?.id, channels);
            } else if (
                searchChannelGroups.length < 10 &&
                selectedChannels.length > 0 &&
                !tryQuery
            ) {
                // Create a new channel group if it doesn't exist
                const addGroupRes = await dispatch(addGroup({ title: uniqueGroupTitle }));
                if (addGroupRes.rc === 0) {
                    searchChannelGroup = addGroupRes.data;
                    await handleChannelsInChunks('add', searchChannelGroup?.id, channels);
                }
            } else if (tryQuery) {
                // Create a new channel group if it doesn't exist
                if (tryQueryTempChannelGroup) {
                    await handleChannelsInChunks('remove', tryQueryTempChannelGroup?.id, tryQueryTempChannelGroup.channels);
                    await handleChannelsInChunks('add', tryQueryTempChannelGroup?.id, channels);
                } else if (tryQueryTempChannelGroup === undefined) {
                    const addGroupRes = await dispatch(
                        addGroup({
                            title: `${config.searchChannelGroupTitle + 'tryQuery'}`
                        })
                    );
                    if (addGroupRes.rc === 0) {
                        tryQueryTempChannelGroup = addGroupRes.data;
                        await handleChannelsInChunks('add', tryQueryTempChannelGroup?.id, channels);
                    }
                }
            }
            return tryQuery ? tryQueryTempChannelGroup?.id : searchChannelGroup?.id;
        } catch (error) {
            console.error('Error creating channel group:', error);
        }
    };

    const filterClipsByBackfill = (notifIds: number[]) => {
        const dataToBeSet = data.filter((clip) => !notifIds.includes(clip.notifID));
        setData(dataToBeSet);
    };

    const organizeNotifications = (
        clips: MentionsVisualizerTypes.Clip[]
    ): (MentionsVisualizerTypes.SuperNotification | MentionsVisualizerTypes.Clip)[] => {
        const superNotifications: MentionsVisualizerTypes.Clip[] = [];
        const basicNotifications: MentionsVisualizerTypes.Clip[] = [];
        const individualNotifications: MentionsVisualizerTypes.Clip[] = [];

        clips.forEach((clip: MentionsVisualizerTypes.Clip) => {
            const { mentionId, isMention } = clip;

            // If isMention is 100, it's a super notification
            if (
                isMention === 100 &&
                mentionId > 0 &&
                checkProfileIsSuper(clip.profileID)
            ) {
                //if isMention is 100 and mentionId > 0, it's a super notification and put it into superNotifications array
                const superNotification = clips.find(
                    (sn) => sn.mentionId === mentionId && sn.profileID === clip.profileID
                );
                if (superNotification) {
                    superNotifications.push({
                        ...clip,
                        basicNotifications: []
                    });
                }
            }
            // If isMention is 11 and mentionId > 0, it's a basic notification
            else if (isMention === 11 && mentionId > 0) {
                basicNotifications.push(clip);
            }
            // If isMention is 0 and mentionID is also 0 and it's not a super notification, it's an individual notification
            else if (
                isMention === 0 &&
                mentionId === 0 &&
                !checkProfileIsSuper(clip.profileID)
            ) {
                individualNotifications.push(clip);
            }
        });

        // Associate basic notifications with their respective super notifications
        basicNotifications.forEach((clip: MentionsVisualizerTypes.Clip) => {
            const { mentionId } = clip;
            const superNotification = superNotifications.find(
                (sn) => sn.mentionId === mentionId && sn.profileID === clip.profileID
            );
            if (superNotification) {
                superNotification.basicNotifications.push(clip);
            }
        });

        // Return an array that includes both super notifications and individual notifications
        return [...superNotifications, ...individualNotifications];
    };

    const checkProfileIsSuper = (profileId: string | number) => {
        for (const profileGroup of profileGroups) {
            for (const profile of profileGroup.profiles) {
                if (profile.id === profileId && profile.isSuper === 1) {
                    return true;
                }
            }
        }
        return false;
    };

    const filterTryQueryClipsByLanguage = (
        clips: MentionsVisualizerTypes.Clip[],
        language: string
    ) => {
        if (language === '**') {
            return clips;
        } else {
            return clips.filter(
                (clip: MentionsVisualizerTypes.Clip) => clip.language === language
            );
        }
    };

    const fetchClips = async (profileIds: string[], dateRange = selectedDateRange) => {
        try {

            const clipPromises = profileIds.map((profileId) =>
                APIClips.getClips(
                    profileId,
                    dateToString(dateRange.startDate),
                    dateToString(dateRange.endDate)
                )
            );

            const clipResponses = await Promise.all(clipPromises);
            const allClips = clipResponses.flatMap((res) => res.data);
            const tryQueryClips = tryQuery
                ? filterTryQueryClipsByLanguage(allClips, tryQuery.lang)
                : [];
            const clipsToUse = organizeNotifications(allClips);
            const allSuccessful = clipResponses.every((res) => res.rc === 0);
            if (allSuccessful) {
                const clipsToShow = isSearch
                    ? tryQuery
                        ? tryQueryClips
                        : allClips
                    : clipsToUse;
                //add queries to their basic notifications array of objects in clipsToShow
                let dataWithQueries;
                if (!isSearch) {
                    const profilePromises = profileIds.map((profileId) =>
                        APIProfiles.get(profileId)
                    );
                    const profileResponses = await Promise.all(profilePromises);
                    const allProfileQueries = profileResponses.map(
                        (res: any) => res.data
                    );
                    dataWithQueries = clipsToShow.map(
                        (clip: MentionsVisualizerTypes.Clip) => {
                            const profileQuery = allProfileQueries.find(
                                (profile: any) => profile.id === clip.profileID
                            );
                            //const uniqueQueries = new Set();
                            if (profileQuery && clip.basicNotifications) {
                                return {
                                    ...clip,
                                    basicNotifications: clip.basicNotifications.map(
                                        (
                                            basicNotification: MentionsVisualizerTypes.Clip
                                        ) => {
                                            // Try to get matches for `clip.language`
                                            const languageQueries =
                                                profileQuery.queries[
                                                    clip.language
                                                ]?.superQuery.filter(
                                                    (query: any) =>
                                                        query.id === basicNotification.qid
                                                ) || [];

                                            // If there are no matches for `clip.language`, fall back to `"**"`
                                            const fallbackQueries =
                                                languageQueries.length === 0
                                                    ? profileQuery.queries[
                                                          '**'
                                                      ]?.superQuery.filter(
                                                          (query: any) =>
                                                              query.id ===
                                                              basicNotification.qid
                                                      ) || []
                                                    : languageQueries;

                                            // Create a unique set of queries to avoid duplicates
                                            const uniqueQueries = new Set(
                                                fallbackQueries
                                            );

                                            basicNotification.queries =
                                                Array.from(uniqueQueries);
                                            return basicNotification;
                                        }
                                    )
                                };
                            } else {
                                return clip;
                            }
                        }
                    );
                }
                //queries is an array of objects with query property
                if (dataWithQueries) {
                    setData(dataWithQueries);
                } else {
                    setData(clipsToShow);
                }


            }
        } catch (error) {
            console.error('Error fetching clips:', error);
        }
    };

    const fetchEditedClips = async (profileIds: string[]) => {
        try {

            const clipPromises = profileIds.map((profileId) =>
                APIClips.getEditedClips(
                    profileId,
                    dateToString(selectedDateRange.startDate),
                    dateToString(selectedDateRange.endDate)
                )
            );
            const clipResponses = await Promise.all(clipPromises);
            const allClips = clipResponses.flatMap((res) => res.data);
            setData(
                allClips.filter((clip) => {
                    return Object.keys(clip).length > 0;
                })
            );

        } catch (error) {
            console.error('Error fetching clips:', error);
        }
    };

    const conditionalChannelGroup = async (searchQuery?: string, queryIssue?: string) => {
        // Create a new temporary channel group or update an existing one
        if (!isToggleChannels && (!selectedChannels || selectedChannels.length === 0)) {
            return null;
        }
        if (selectedChannels && selectedChannels.length > 0 && !isToggleChannels && !queryIssue && !tryQuery) {
            return await createOrUpdateChannelGroupForProfile(
                selectedChannels,
                searchQuery
            );
        } else if (selectedChannelGroup && isToggleChannels) {
            return selectedChannelGroup.value.id;
        } else if (tryQuery && tryQuery.channelGroup && isToggleChannels) {
            return tryQuery.channelGroup.id;
        } else if (
            tryQuery &&
            !isToggleChannels &&
            selectedChannels &&
            selectedChannels.length > 0
        ) {
            return await createOrUpdateChannelGroupForProfile(
                selectedChannels,
                'tryQuery'
            );
        } else {
            return null;
        }
    };

    const handleSearchCallRc = useCallback((response: any) => {
        switch (response.rc) {
            case 0:
                if(setIsLoadingMsg){
                    setIsLoadingMsg('');
                }
                break;
            case 1703:
                setLoading(false)
                if (setModalType) {
                    setModalType('searchForbiddenChannels');
                }
                setSearchForbiddenChannels(response.forbiddenChannels);
                break;
            case 1704:
                if(onSearchIsSlow){
                    onSearchIsSlow();
                }
                break;
            case 90013:
                if(setQueryIssue) { setQueryIssue(t('The query syntax is invalid. Please check the query.')); }
                setLoading(false);
                
                break;
        }
    },[onSearchIsSlow, setIsFetching, isFetching])

    const failsafeChannelGroups = async (channelGroups: ChannelGroup[]) => {
        const searchProfileIds = searchProfileGroup?.profiles.map(
            (group: any) => group.id
        ) || [];

        const detailsOfSearchIds = await Promise.all(
            searchProfileIds.map((id) => APIProfiles.get(id))
        );

        let invalidProfiles = [];
        const invalidChannelGroups = channelGroups.filter(
            (group: ChannelGroup) =>
                group.title === config.searchChannelGroupTitle ||
                group.title === config.searchChannelGroupTitle + '"'
        ); 
        if(invalidChannelGroups.length > 0){
            invalidProfiles = detailsOfSearchIds.filter(
                (profile: any) =>
                    //compare profile.data.channelGroupId with invalidChannelGroups objects id
                    invalidChannelGroups.some((group: ChannelGroup) => group.id === profile.data.channelGroupId)
            );
        }         

        if (invalidProfiles.length > 0 && invalidChannelGroups.length > 0) {
            await Promise.all(invalidProfiles.map(async (profile: any) => {
                await APIProfiles.delete(profile.data.id);
                dispatch(deleteProfile(searchProfileGroup?.id, profile.data.id));
                if(invalidChannelGroups.length > 0){
                    await Promise.all(invalidChannelGroups.map(async (group: ChannelGroup) => {
                        await dispatch(deleteGroup(group.id));
                    }));
                }
            }));
        }
        else if(invalidChannelGroups.length > 0 && invalidProfiles.length === 0){
            await Promise.all(invalidChannelGroups.map(async (group: ChannelGroup) => {
                await dispatch(deleteGroup(group.id));
            }));
        }
    }

    const fetchSearchQueryClips = useCallback(
        async (
            searchQuery: string,
            selectedDateRange: MentionsVisualizerTypes.DateRange,
            queryIssue?: string
        ) => {
            if (isFetching) return;
            setIsFetching(true);
           
            let res;

            const searchChannelGroupId = await conditionalChannelGroup(searchQuery, queryIssue);
            const channelGroups = await dispatch(getGroups());
            try {

                if (queryIssue) {
                    setLoading(false);
                    return;
                }

                const tryQueryProfileGroup = profileGroups.find(
                    (group: any) => group.id === tryQuery?.id
                ) || null;

                // Create a new search group if it doesn't exist
                if (!searchGroupExists && !tryQuery) {
                    res = await APIProfileGroups.post({
                        title: config.searchQueryGroupTitle
                    });
                } else if (tryQueryProfileGroup) {
                    res = tryQueryProfileGroup;
                } else {
                    res = searchProfileGroup;
                }

                // Search profile prototype to create
                const searchProfileToCreate = {
                    title: tryQuery ? (tryQuery.profileTitle !== '' ? tryQuery.profileTitle : tryQuery.query) : searchQuery,
                    queries: tryQuery
                        ? tryQuery.queries
                        : {
                              ['**']: {
                                  standardQuery: { query: searchQuery },
                                  superQuery: []
                              }
                          },
                    isSuper: tryQuery ? tryQuery.isSuper : false,
                    isEnabled: true,
                    isSearchAgent: true,
                    notifyIM: true,
                    notifyEmail: 0,
                    profileGroupId: res.id,
                    channelGroupId: searchChannelGroupId,
                    shareGroups: [],
                    updatedShareGroups: [],
                    logos: [],
                    initShareGroups: [],
                    hasQuery: true
                };

                const tryQueryIdCondition = async (conditionalRes: any) => {
                    if(tryQuery){
                        if(tryQuery.qid !== undefined && tryQuery.qid !== null){
                            return tryQuery.qid
                        }else{
                        // TODO: refactor: pass data here instead of calling APIProfiles.get(conditionalRes.data.id) again
                        const profileDetails = await APIProfiles.get(conditionalRes.data.id);
                            const { queries } = profileDetails.data;
                            let qid = conditionalRes.data.qids.find((qid: number) => {
                                const languageQueries = queries[tryQuery.lang];
                                if(tryQuery.isSuper){
                                    return languageQueries.superQuery.some(
                                        (query: any) =>
                                            query.id === qid &&
                                            query.query ===
                                                (tryQuery && tryQuery.query && !searchQuery
                                                    ? tryQuery.query
                                                    : searchQuery)
                                    );
                                } else {
                                    return languageQueries.standardQuery.id === qid;
                                }
                            })
                            if(qid === undefined){
                                qid = 0;
                            }
                            return qid;
                        }
                    }
                }

                // Check if the search group was created successfully
                if (
                    (res && res.rc === 0) ||
                    searchGroupExists ||
                    tryQueryProfileGroup
                    //    && !tryQueryProfileGroup.sharedBy !== null
                ) {
                    // Check if the search profile already exists in Search Group, only if the search group exists
                    const existingProfile: any =
                        searchProfileGroup &&
                        searchProfileGroup.profiles.find(
                            (profile: MentionsVisualizerTypes.ProfileTypes) => {
                                //if searchQuery is longer than 100 chars then compare the first 100 chars
                                if (searchQuery.length > 100) {
                                    return (
                                        profile.title === searchQuery.substring(0, 100)
                                    );
                                } else {
                                    return profile.title === searchQuery;
                                }
                            }
                        );
                        
                        if (existingProfile || tryQuery?.profileID) {
                        const profileDetails = await APIProfiles.get(
                            tryQuery ? tryQuery.profileID : existingProfile?.id
                        );
                        if (profileDetails.rc === 0) {
                            const { queries, channelGroupId, isSuper, isEnabled, notifyEmail, notifyIM } =
                                profileDetails.data;

                            const queryMismatch =
                                queries &&
                                Object.keys(queries).some((key) => {
                                    const currentQuery = queries[key].standardQuery.query;
                                    const enteredQuery = searchQuery || tryQuery.query;
                                    return currentQuery !== enteredQuery;
                                });
                            let tryQueryTempChannelGroup = channelGroups.find(
                                (group: ChannelGroup) =>
                                    group.title ===
                                    `${config.searchChannelGroupTitle + 'tryQuery'}`
                            );

                            const channelGroupMismatch =
                                channelGroupId !==
                                (searchChannelGroupId || tryQueryTempChannelGroup?.id);

                            if (
                                (tryQuery
                                    ? (queryMismatch ||
                                          channelGroupMismatch ||
                                          selectedChannels.length === 0) &&
                                      tryQueryProfileGroup?.sharedBy === null
                                    : (queryMismatch || channelGroupMismatch))
                            ) {
                                const updateObject = {
                                    ...searchProfileToCreate,
                                    title: tryQuery
                                        ? tryQuery.profileTitle || tryQuery.query
                                        : searchQuery || existingProfile?.title,
                                    isSuper: tryQuery ? tryQuery.isSuper : isSuper,
                                    isEnabled: isEnabled,
                                    channelGroupId: searchChannelGroupId,
                                    notifyEmail: notifyEmail,
                                    notifyIM: notifyIM,
                                    queries: tryQuery
                                        ? {
                                              [tryQuery.lang]: {
                                                  standardQuery: !tryQuery.isSuper ? {
                                                      id: tryQuery.queries[tryQuery.lang]
                                                          .standardQuery.id,
                                                      query: searchQuery || tryQuery.query
                                                  } : {},
                                                  superQuery: tryQuery.isSuper ? 
                                                    //change query inside queries[tryQuery.lang].superQuery
                                                    tryQuery.queries[tryQuery.lang].superQuery.map((q: any) => {
                                                            if (tryQuery.qid === q.id) {
                                                            return {
                                                                    ...q,
                                                                    query: tryQuery && tryQuery.query && !searchQuery ?  q.query : searchQuery
                                                            };
                                                            }         
                                                        return q;
                                                    })
                                                  : []
                                              }
                                          }
                                        : Object.keys(queries).reduce((acc, key) => {
                                              acc[key] = {
                                                  standardQuery: (
                                                      tryQuery
                                                          ? !tryQuery.isSuper
                                                          : !isSuper
                                                  )
                                                      ? {
                                                            id: queries[key].standardQuery
                                                                .id,
                                                            query: searchQuery
                                                        }
                                                      : {},
                                                  superQuery: (
                                                      tryQuery
                                                          ? tryQuery.isSuper
                                                          : isSuper
                                                  )
                                                      ? queries[key].superQuery
                                                      : []
                                              };
                                              return acc;
                                          }, {} as any),
                                    id: tryQuery
                                        ? tryQuery.profileID
                                        : existingProfile?.id
                                };

                                dispatch(
                                    updateProfile(
                                        tryQueryProfileGroup?.id ||
                                            searchProfileGroup?.id,
                                        updateObject
                                    ),
                                    tryQueryProfileGroup?.id || searchProfileGroup?.id
                                );
                                const updateRes = await APIProfiles.put(
                                    tryQuery
                                        ? tryQuery.profileID
                                        : existingProfile?.id,
                                    updateObject
                                );

                                if (updateRes.rc === 0) {
                                    failsafeChannelGroups(channelGroups);
                                    if (onProfileSavedSuccess) { onProfileSavedSuccess(updateRes); }
                                    // TODO: pass query index + updateQueryGroup(languageIdx, index, data) from Profile.js and call it here (to update query in profiles widget) ?                                    
                                    dispatch(getProfileGroups());
                                    // Profile exists, fetch clips
                                    const searchCall = await APIClips.getSearchQueryClips(
                                        tryQuery ? tryQuery.profileID : existingProfile?.id,
                                        dateToString(selectedDateRange.startDate),
                                        dateToString(selectedDateRange.endDate),
                                        [1703,1704,90013],
                                        await tryQueryIdCondition(updateRes)
                                        

                                    );
                                    handleSearchCallRc(searchCall);
                                    if (searchCall.rc === 0) {
                                        fetchClips(
                                            [
                                                tryQuery
                                                    ? tryQuery.profileID + 't'
                                                    : existingProfile?.id + 't'
                                            ],
                                            selectedDateRange
                                        );
                                        dispatch(getProfileGroups());
                                    }
                                }
                            }else{
                                const searchCall = await APIClips.getSearchQueryClips(
                                    tryQuery ? tryQuery.profileID : existingProfile?.id,
                                    dateToString(selectedDateRange.startDate),
                                    dateToString(selectedDateRange.endDate),
                                    [1703,1704,90013]
                                );
                                handleSearchCallRc(searchCall);
                                if (searchCall.rc === 0) {
                                    fetchClips(
                                        [
                                            tryQuery
                                                ? tryQuery.profileID + 't'
                                                : existingProfile?.id + 't'
                                        ],
                                        selectedDateRange
                                    );
                                    dispatch(getProfileGroups());
                                }
                            }
                        }
                        
                    } else {
                        if (
                            searchProfileGroup &&
                            searchProfileGroup.profiles.length >= 10 && !tryQuery
                        ) {
                            // Update the first profile if there are already 10 profiles
                            const firstProfile: any =
                                searchProfileGroup.profiles[0 as any];
                            const firstProfileDetails = await APIProfiles.get(
                                firstProfile.id
                            );
                            //const uniqueGroupTitle = `${config.searchChannelGroupTitle + searchQuery}`;
                            const updatedSearchGroups = await dispatch(getGroups()).then(
                                (res: any) => {
                                    return res.filter((group: ChannelGroup) =>
                                        group.title.startsWith(
                                            config.searchChannelGroupTitle
                                        )
                                    );
                                }
                            );

                            if (
                                firstProfileDetails.rc === 0 &&
                                firstProfileDetails.data.queries
                            ) {
                                let searchChannelGroup = updatedSearchGroups.find(
                                    (group: ChannelGroup) =>
                                        group.title === `${config.searchChannelGroupTitle + firstProfileDetails.data.title}` ||
                                        group.title === `${config.searchChannelGroupTitle + stringToHash(firstProfileDetails.data.title)}`
                                );
                                const uniqueGroupTitle = searchChannelGroup?.title;
                                const firstChannelGroupId = updatedSearchGroups.find(
                                    (group: ChannelGroup) =>
                                        group.title === uniqueGroupTitle
                                )?.id;
                                // const queryId = firstProfileDetails.data.queries[
                                //     tryQuery ? tryQuery.lang && "**" : Object.keys(firstProfileDetails.data.queries)[0]
                                // ].standardQuery
                                //     .id;
                                if (firstChannelGroupId) {
                                    const updateRes = await dispatch(
                                        updateGroup(firstChannelGroupId, {
                                            title: `${
                                                config.searchChannelGroupTitle +
                                                stringToHash(searchProfileToCreate.title)
                                            }`
                                        })
                                    );
                                    if (updateRes.rc === 0) {
                                        if (selectedChannels.length > 0) {
                                            await handleChannelsInChunks(
                                                'remove',
                                                firstChannelGroupId,
                                                updatedSearchGroups.find(
                                                    (group: ChannelGroup) =>
                                                        group.title ===
                                                        uniqueGroupTitle
                                                ).channels
                                            )
                                            await handleChannelsInChunks(
                                                'add',
                                                firstChannelGroupId,
                                                selectedChannels
                                            )
                                        }
                                        const firstChannelGroup =
                                            updatedSearchGroups.find(
                                                (group: ChannelGroup) =>
                                                    group.id === firstChannelGroupId
                                            );
                                        const deleteRes = await APIProfiles.delete(firstProfile.id);
                                        if (deleteRes.rc === 0) {
                                            dispatch(deleteProfile(searchProfileGroup.id, firstProfile.id));
                                        }
                                        const createRes = await APIProfiles.post({
                                            ...searchProfileToCreate,
                                            channelGroupId:
                                                firstChannelGroup.channels.length > 0 &&
                                                selectedChannels.length === 0
                                                    ? null
                                                    : searchChannelGroupId ||
                                                      firstChannelGroupId
                                        });
                                      
                                        if (createRes.rc === 0) {
                                            if (onProfileSavedSuccess) { onProfileSavedSuccess(createRes); }
                                            failsafeChannelGroups(channelGroups);
                                            dispatch(
                                                addProfile(
                                                    tryQuery
                                                        ? tryQueryProfileGroup?.id
                                                        : searchProfileGroup.id,
                                                    {
                                                        ...searchProfileToCreate,
                                                        id: createRes.data.id
                                                    }
                                                )
                                            );
                                            if (tryQuery && setTryQueryProfileId) {
                                                setTryQueryProfileId(createRes.data.id);
                                            }
                                            const searchCall =
                                                await APIClips.getSearchQueryClips(
                                                    createRes.data.id,
                                                    dateToString(
                                                        selectedDateRange.startDate
                                                    ),
                                                    dateToString(
                                                        selectedDateRange.endDate
                                                    ),
                                                    [1703,1704,90013],
                                                    await tryQueryIdCondition(createRes)
                                                );
                                            handleSearchCallRc(searchCall);
                                            if (searchCall.rc === 0) {
                                                fetchClips(
                                                    [createRes.data.id + 't'],
                                                    selectedDateRange
                                                );
                                            }
                                        }
                                    }
                                    dispatch(getProfileGroups());
                                } else if (!firstChannelGroupId) {
                                    const deleteRes = await APIProfiles.delete(firstProfile.id);
                                    if (deleteRes.rc === 0) {
                                        dispatch(deleteProfile(searchProfileGroup.id, firstProfile.id));
                                    }
                                    const createRes = await APIProfiles.post(
                                        searchProfileToCreate
                                    );

                                    if (createRes.rc === 0) {
                                        if (onProfileSavedSuccess) { onProfileSavedSuccess(createRes); }
                                        failsafeChannelGroups(channelGroups);
                                        dispatch(
                                            addProfile(
                                                tryQuery
                                                    ? tryQueryProfileGroup?.id
                                                    : searchProfileGroup.id,
                                                {
                                                    ...searchProfileToCreate,
                                                    id: createRes.data.id
                                                }
                                            )
                                        );
                                        if (tryQuery && setTryQueryProfileId) {
                                            setTryQueryProfileId(createRes.data.id);
                                        }
                                        const searchCall =
                                            await APIClips.getSearchQueryClips(
                                                createRes.data.id,
                                                dateToString(selectedDateRange.startDate),
                                                dateToString(selectedDateRange.endDate),
                                                [1703,1704,90013],
                                                await tryQueryIdCondition(createRes)
                                            );
                                        handleSearchCallRc(searchCall);
                                        if (searchCall.rc === 0) {
                                            fetchClips(
                                                [createRes.data.id + 't'],
                                                selectedDateRange
                                            );
                                        }
                                    }
                                }
                            }
                        } else if (
                            searchProfileGroup &&
                            searchProfileGroup.profiles.length < 10
                        ) {
                            // Create a new profile if there are less than 10 profiles
                            const createRes = await APIProfiles.post(
                                searchProfileToCreate
                            );
                            if (createRes.rc === 0) {
                                if (onProfileSavedSuccess) { onProfileSavedSuccess(createRes); }
                                failsafeChannelGroups(channelGroups);
                                dispatch(
                                    addProfile(
                                        tryQuery
                                            ? tryQueryProfileGroup?.id
                                            : searchProfileGroup.id,
                                        {
                                            ...searchProfileToCreate,
                                            id: createRes.data.id
                                        }
                                    )
                                );
                                if (tryQuery && setTryQueryProfileId) {
                                    setTryQueryProfileId(createRes.data.id);
                                }
                                dispatch(getProfileGroups());
                                const searchCall = await APIClips.getSearchQueryClips(
                                    createRes.data.id,
                                    dateToString(selectedDateRange.startDate),
                                    dateToString(selectedDateRange.endDate),
                                    [1703,1704,90013],
                                    await tryQueryIdCondition(createRes)
                                );
                                handleSearchCallRc(searchCall);
                                if (searchCall.rc === 0) {
                                    fetchClips(
                                        [createRes.data.id + 't'],
                                        selectedDateRange
                                    );
                                }
                            }
                        } else {
                            // Create a new profile if there are less than 10 profiles and tryQuery does not exist
                            const createRes = await APIProfiles.post(
                                searchProfileToCreate
                            );

                            if (createRes.rc === 0) {
                                if (onProfileSavedSuccess) { onProfileSavedSuccess(createRes); }
                                failsafeChannelGroups(channelGroups);
                                const groups = dispatch(getProfileGroups());
                                if(groups){
                                    dispatch(
                                        addProfile(
                                            tryQuery
                                                ? tryQueryProfileGroup?.id
                                                : res.id,
                                            {
                                                ...searchProfileToCreate,
                                                id: createRes.data.id
                                            }
                                        )
                                    );
                                }
                                if (tryQuery && setTryQueryProfileId) {
                                    setTryQueryProfileId(createRes.data.id);
                                }
                                const searchCall = await APIClips.getSearchQueryClips(
                                    createRes.data.id,
                                    dateToString(selectedDateRange.startDate),
                                    dateToString(selectedDateRange.endDate),
                                    [1703,1704,90013],
                                    await tryQueryIdCondition(createRes)
                                );
                                handleSearchCallRc(searchCall);
                                if (searchCall.rc === 0) {
                                    fetchClips(
                                        [createRes.data.id + 't'],
                                        selectedDateRange
                                    );
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching clips:', error);
            } finally {
                setIsFetching(false);
            }
        },
        [
            selectedDateRange,
            searchGroupExists,
            searchProfileGroup,
            selectedChannelGroup,
            dispatch,
            selectedChannels,
            isToggleChannels,
            loading,
            tryQuery,
            isFetching,
            handleSearchCallRc
        ]
    );

    const fetchSearchQueryClipsWithLoading = (...args: any[]) => withLoading(fetchSearchQueryClips, ...args);

    const debouncedFetchSearch = useCallback(debounce(fetchSearchQueryClipsWithLoading, 200), [
        fetchSearchQueryClipsWithLoading
    ]);

    useEffect(() => {
        !isSearch && setLoading(true)
    }, [isSearch]);

    return {
        loading,
        data,
        fetchClips: (...args: any[]) => withLoading(fetchClips, ...args),
        fetchEditedClips: (...args: any[]) => withLoading(fetchEditedClips, ...args),
        fetchSearchQueryClips: debouncedFetchSearch,
        filterClipsByBackfill,
        searchForbiddenChannels,
        setSearchForbiddenChannels,
        setLoading
    };
};

export default useFetchClips;
