import React, { useEffect, useState } from 'react';
import styles from './ChannelGroups.module.scss';
import GroupsContainer from './containers/GroupsContainer/GroupsContainer';
import TableContainer from './containers/TableContainer/TableContainer';

import { useDispatch, useSelector } from 'react-redux';
import EditViewContainer from './containers/EditViewContainer/EditViewContainer';
import { createStatus, popupMessage, saveStatus, unSelectGroup, unselectChannels } from 'src/redux/actions/actions.channelGroupsWidget';
import Popup from '../common/basicElements/Popup/Popup';

import { addChannels,addGroup,removeChannels } from 'src/redux/actions/actions.channelGroups';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import TextInput from '../common/basicElements/TextInput/TextInput';
import { trackEvent } from '../common/helpers';
import config from 'src/config/config';


const ChannelGroups = () => {
    const dispatch = useDispatch();
    const { editGroupOpen, setPopupMessage, selectedChannels, saveStatusLog, searchedChannels, selectedGroupTitle, filteredChannels, selectedGroupId } = useSelector(
        (state) => state.channelGroupsWidgetReducer
    );
    const channelGroups = useSelector((state) => state.channelGroupReducers);

    const matomo = useSelector((state) => state.matomo);

    
    const isAdminUser = false; //!TODO get user permission from backend
    const [buttonType, setButtonType] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedGroupOptions, setSelectedgroupOptions] = useState([]);
    const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
    const [newGroupName, setNewGroupName] = useState('')

    const channels = searchedChannels || filteredChannels
    const channelIdsInGroup = channels?.map((channel) => channel?.Id || channel?.id);
    
    const channelsNotInGroup = selectedChannels?.filter((channel) => !channelIdsInGroup?.includes(channel?.id || channel?.Id));
    const excludedChannels = channels?.filter(channel => channel.isNotAvailable || channel.isUnique);

    const {t} = useTranslation()

    const filterIdBySelectedTitle = (groupOption) => {
        const filteredId = channelGroups?.filter((group) => {
            if(group?.title === groupOption?.label){
                return group?.id
            }
        })
        return filteredId[0]?.id
    }
    

    const lowerCaseSelectedChannels = selectedChannels
    ?.filter(channel => !excludedChannels?.includes(channel))
    ?.map(channel => 
        Object.keys(channel).reduce((result, key) => {
            result[key.toLowerCase()] = channel[key];
            return result;
        }, {})
    );

    useEffect(() => {
        saveStatusLog?.isError &&
            dispatch(
                popupMessage(
                    `${t('cg_could_not_create')} ${newGroupName}. ${t(
                        'cg_title_exists'
                    )}.`
                )
            );
            setNewGroupName('');
    }, [saveStatusLog?.isError]);

    const clearPopupLogic = () => {
        // dispatch(saveStatus(0));
        isOpen && setIsOpen(false);
        dispatch(popupMessage(''));    
        setIsAddButtonClicked(false);
        setNewGroupName('');
        setSelectedgroupOptions([])
    };
    
    const handleAddChannels = (groupId, channels) => {
        dispatch(addChannels(groupId, channels?.filter(channel => !channel.isnotavailable && !channel.isunique)))?.then((res) => {
            setButtonType('add');
            if (res.rc === 0) {
                trackEvent(matomo, 'Channel Groups', 'Channels Added', {
                    addedChannelIds: channels?.filter(channel => !channel.isnotavailable && !channel.isunique).map(channel => channel.id).join(','),
                    groupId: groupId
                });
                let message = `${t('Added Channels')}`;
                dispatch(popupMessage(message));
                dispatch(unselectChannels());
                setSelectedgroupOptions([]);
            } else {
                dispatch(createStatus(res.rc));
            }
        });
    };
    
    const addChannelsHandler = () => {
        setIsAddButtonClicked(true);
    
        const handleAddChannelsToGroup = (groupId) => {
            const existingChannels = channelGroups?.find((group) => group?.id === groupId)?.channels?.filter((channel) => {
                return !channel.isnotavailable && !channel.isunique;
            }) || [];
            //do not consider excluded channels
            const duplicateChannels = []
            const newChannels = lowerCaseSelectedChannels.filter((newChannel) => {
                const isDuplicate = existingChannels?.some(
                    (existingChannel) => existingChannel?.id === newChannel?.id
                );
                if (isDuplicate) {
                    duplicateChannels.push(newChannel);
                    return false;
                }
                return true;
            });
            if (newChannels.length === 0) {
                const message = `${t(
                    'Channel With Same Name'
                )}`; /*${/*duplicateChannels.map(channel => channel.name).join(", ")}*/
                dispatch(popupMessage(message));
            } else{
                handleAddChannels(groupId, newChannels);
            }
        };
    
        if (newGroupName.length > 0) {
            dispatch(addGroup({ title: newGroupName }, [90102])).then((res) => {
                if (res.rc === 0) {
                    dispatch(saveStatus(0));
                    const newGroupId = res.data.id;
                    handleAddChannelsToGroup(newGroupId);
                } else {
                    dispatch(saveStatus(res?.rc));
                }
            });
        }
        
        if (selectedGroupOptions.length > 0) {
            selectedGroupOptions?.forEach((groupOption) => {
                const groupId = filterIdBySelectedTitle(groupOption);
                handleAddChannelsToGroup(groupId);
            });
        }
        
        if (newGroupName.length === 0 && selectedGroupOptions.length === 0) {
            console.error('There was a problem happened while adding channels');
        }
    }
    

    const removeChannelsHandler = () => {
        dispatch(removeChannels(selectedGroupId, lowerCaseSelectedChannels)).then((res) => {
            if(res.rc === 0){
                trackEvent(matomo, 'Channel Groups', 'Channels Deleted', {
                    deletedChannelIds: lowerCaseSelectedChannels.map(channel => channel.id).join(','),
                    groupId: selectedGroupId
                });
                dispatch(unSelectGroup());   
                clearPopupLogic()
            }else{
                dispatch(createStatus(res.rc))
            }
        })
    };

    const addNewGroupHandler = (e) => {
        setNewGroupName(e.target.value)
    }
    
    //Sort Channel Groups
    const sortedChannelGroupTitles = channelGroups
    ?.sort((a, b) => a?.title.localeCompare(b?.title))
    ?.filter((group) => !group?.title.includes(config.searchChannelGroupTitle));
    
    const channelGroupTitle = sortedChannelGroupTitles?.map((group) => {
        return { label: group?.title, value: group?.title }
    })

    const channelsWillRemovedInGroup = selectedChannels?.filter(channel => {
        return !channelsNotInGroup?.some(channelsNotInGroup => channelsNotInGroup?.id === channel?.id);
      })

    const noAccessSelectedChannels = selectedChannels?.filter(channel => channel.isNotAvailable || channel.isUnique);
    const addRemovePopupButtonHandler = () => {

        if (setPopupMessage) {
            return (
                <Popup wrapperStyle={{position:'fixed', zIndex: '9999',  insetBlockStart: 'unset', insetBlockEnd: 0, height:'calc(100% - 2.2rem)'}} size="auto" blockContent={true} onOk={clearPopupLogic}>
                    <div >
                        <p style={{ overflow: 'hidden' }}>
                            {setPopupMessage?.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
                ))}</p>
                    </div>
                </Popup>
            );
        }
        if (isOpen && buttonType === 'add') {
            return (
                <Popup
                    size="auto"
                    blockContent={true}
                    onCancel={clearPopupLogic}
                    okDisabled={true}
                    addDisabled={
                        isAddButtonClicked ||
                        ((!selectedGroupOptions?.length ||
                            (selectedGroupOptions === null && !newGroupName.length)) &&
                            !newGroupName?.length) ||
                            (selectedChannels?.length > 0 && selectedChannels?.length === noAccessSelectedChannels?.length)
                    }
                    onAdd={addChannelsHandler}
                    wrapperStyle={{
                        position: 'fixed',
                        insetBlockStart: 'unset',
                        insetBlockEnd: 0,
                        height: 'calc(100% - 2.2rem)'
                    }}
                >
                    <div className={styles.addRemovePopupContainer}>
                        {/* <h3>{t("Add Selected Channels")}</h3> */}
                        <p>
                            {t('You have selected') + ' '}
                            {selectedChannels?.length} {t('Channels count')}.
                            {noAccessSelectedChannels?.length > 0 && (
                                <span className={styles.noAccessWarning}>
                                    {' '}
                                 {selectedChannels?.length-noAccessSelectedChannels?.length}
                                 {' '}
                                    {t('Channels count')}
                                    {' '}
                                    {t(`cannot be added`)}
                                </span>
                            )}
                        </p>
                        <h4>{t('Add your selection')}</h4>
                        <p>{t('Selected channel group')}</p>
                        {/* <p>
                            {t("Dropdown menu contains")}
                        </p> */}

                        <ReactSelect
                            isMulti
                            onChange={(...props) => setSelectedgroupOptions(...props)}
                            className={styles.selectBox}
                            options={channelGroupTitle}
                            value={selectedGroupOptions}
                            closeMenuOnSelect={
                                channelGroupTitle?.length - 1 ===
                                selectedGroupOptions?.length
                            }
                            backspaceRemovesValue
                        />
                        <p>{t('Enter a name for your')}</p>
                        <TextInput
                            placeholder={t('Enter a name')}
                            onChange={addNewGroupHandler}
                            value={newGroupName}
                        />
                    </div>
                </Popup>
            );
        }

        if (isOpen && buttonType === 'remove') {
            return (
                <Popup
                    size="auto"
                    blockContent={true}
                    onCancel={clearPopupLogic}
                    okDisabled={true}
                    onRemove={removeChannelsHandler}
                    wrapperStyle={{position:'fixed', insetBlockStart: 'unset', insetBlockEnd: 0, height:'calc(100% - 2.2rem)'}}
                >
                     <div className={styles.addRemovePopupContainer}>
                        <h3>{t("Delete Channels")}</h3>
                        <p>{t("You have selected" ) + " "}{channelsWillRemovedInGroup?.length || 0} {t("Channels in group")} "{selectedGroupTitle || ''}".</p>
                        <p>
                            {t("Really delete channels")}
                        </p>
                    </div>
                </Popup>
            );
        }
    };

    return (
        <>
            {addRemovePopupButtonHandler()}
            <div className={styles.mainContainer}>
                {!editGroupOpen ? (
                    <>
                        <GroupsContainer isAdminUser={isAdminUser} />
                        <TableContainer
                            setIsOpen={setIsOpen}
                            channelsNotInGroup={channelsNotInGroup}
                            channelsWillRemovedInGroup={channelsWillRemovedInGroup}
                            setButtonType={setButtonType}
                            isChannelGroupsWidget={true}
                        />
                    </>
                ) : (
                    <EditViewContainer isAdminUser={isAdminUser} />
                )}
            </div>
        </>
    );
};

export default ChannelGroups;
